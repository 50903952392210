import React from 'react';
import Index from './Landing Page/Index';

const App = () => {
  return (
    <div className="app">
      <Index />
    </div>
  )
}

export default App;