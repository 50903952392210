import React from 'react';
import "../../particle.css";

function Particles() {
  return (
    <div className="wrap">
    </div>
  );
}

export default Particles;